import { Injectable } from '@angular/core';
import { CrmUserService } from 'common-module/user';
import { CrmDictionary } from 'common-module/core/types';
import { CrmPaginated } from 'common-module/table';
import { User } from '@app/core/model/User';
import { Observable } from 'rxjs';

export interface UserStats {
  count: number;
  status: string;
  type: string;
}

@Injectable({ providedIn: 'root' })
export class UserService extends CrmUserService<User> {
  search(params: CrmDictionary) {
    return this.endpoint.request<CrmPaginated<User>>('GET', 'search', {
      params,
    });
  }

  override getUser(id: string): Observable<User> {
    return super.getUser(id);
  }

  anonymizeUser(id: string) {
    return this.endpoint.request('POST', `${id}/anonymise`);
  }

  usersCount(params?: CrmDictionary) {
    return this.endpoint.request<number>('GET', `count`, { params });
  }

  userStats(params?: CrmDictionary) {
    return this.endpoint.request<UserStats[]>('GET', 'stats', { params });
  }
}
